<template>
    <div class="promoteRank-container">
        <div class="promoteRank-top">
            <el-date-picker
                    v-model="value1"
                    type="daterange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="toggleNewDate"
                    size="medium">
            </el-date-picker>
            <el-input size="medium" class="search-input" clearable
                      placeholder="请输入商品名称" prefix-icon="el-icon-search" v-model="searchValue" style="width: 300px; margin-left: 20px"></el-input>
        </div>
        <div class="promoteRank-select" ref="classifyBox" :style="classifyHeight">
            <span class="select-left">商品分类:</span>
            <div class="select-right">
                <div class="tag-item"
                     :class="{current: goodsClassCurrent === goodsClassItem.id}"
                     v-for="(goodsClassItem, goodsClassIndex) in goodsClassList"
                     @click="toggleGoodsClass(goodsClassItem)"
                     :key="goodsClassItem.id">
                    {{goodsClassItem.name}}
                </div>
            </div>
        </div>
        <el-table :data="goodsList"
                  :style="goodsList.length > 0 ? `min-height: calc(100vh - ${classifyHeight}px - 236px)` : `min-height: calc(100vh - ${classifyHeight}px - 186px)`"
                  size="medium" class="listTable" :header-cell-style="{fontWeight: 'normal', color: '#333'}" :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column label="排行" min-width="86" align="center">
                <template slot-scope="scope">
                    <span class="goods-rank">{{scope.row.sort}}</span>
                </template>
            </el-table-column>
            <el-table-column label="商品" min-width="300">
                <template slot-scope="scope">
                    <a :href="scope.row.goods_url" target="_blank" class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.goods_img_url" alt="">
                        </div>
                        <div class="goods-title text-overflow" :title="scope.row.goods_name">{{scope.row.goods_name}}</div>
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="价格" align="center">
                <template slot-scope="scope">
                    <span>￥{{scope.row.price}}</span>
                </template>
            </el-table-column>
            <el-table-column label="佣金比例" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.rate}}%</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center">
                <template slot="header" slot-scope="scope">
                    <span class="header-event" :class="{current: amountCurrent === 'daily_sale_count'}" @click="toggleAmount('daily_sale_count')">
                        <span>日销量</span>
                        <i class="iconfont header-icon">&#xe771;</i>
                    </span>
                </template>
                <template slot-scope="scope">
                    <div class="crease-box" :class="{current: amountCurrent === 'daily_sale_count'}">
                        <span class="crease-num">{{scope.row.daily_sale_count}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center">
                <template slot="header" slot-scope="scope">
                    <span class="header-event" :class="{current: amountCurrent === 'month_sale_count'}" @click="toggleAmount('month_sale_count')">
                        <span>月销量</span>
                        <i class="iconfont header-icon">&#xe771;</i>
                    </span>
                </template>
                <template slot-scope="scope">
                    <div class="crease-box" :class="{current: amountCurrent === 'month_sale_count'}">
                        <span class="crease-num">{{scope.row.month_sale_count}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" min-width="220" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-s-data" circle @click="toGoodsDetail(scope.row, 'hot')"></el-button>
                    <el-button type="success" icon="el-icon-caret-right" circle @click="toGoodsDetail(scope.row, 'promote')"></el-button>
                    <el-button type="warning" icon="el-icon-s-marketing" circle @click="toGoodsDetail(scope.row, 'broadCaster')"></el-button>
                    <el-button type="danger" icon="el-icon-s-custom" circle @click="toGoodsDetail(scope.row, 'audience')"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" background style="padding-bottom: 10px;" v-if="goodsList.length > 0"
                       :current-page.sync="promoteRankPages.currentPageNum"
                       :page-size="promoteRankPages.eachPageNum"
                       :total="promoteRankPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="promoteRankCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import _ from "lodash";
    export default {
        data() {
            return {
                // 第一栏
                listType: 'date',
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
                datePickerValue: '',
                weekPickerValue: '',
                monthPickerValue: '',
                value1:"",//新的时间选择
                searchValue: '',
                startTime: null,
                endTime: null,

                // 第二栏
                goodsClassList: [],
                goodsClassCurrent: 54,
                goodsClassSecondCurrent: 0,
                chooseClassName: '',
                classifyHeight: '',

                // 表格
                goodsList: [],
                amountCurrent: 'daily_sale_count',
                promoteRankPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getGoodsClass();
            this.getPromoteGoodsList();
        },
        methods: {
            toggleListType(val) {
                this.datePickerValue = ''
                this.weekPickerValue = ''
                this.monthPickerValue = ''
                this.startTime=  null
                this.endTime = null
                this.getPromoteGoodsList()
            },
            toggleNewDate(val){
                if (val === null) {
                    this.startTime = null
                    this.endTime = null
                } else {
                    let day1 = new Date(val[0]);
                    let timesTramp1 = day1.getTime();
                    let day2 = new Date(val[1]);
                    let timesTramp2 = day2.getTime();
                    this.startTime = timesTramp1 / 1000;
                    this.endTime = timesTramp2 / 1000;
                }
                this.getPromoteGoodsList()
            },
            toggleDate(val) {
                this.getPromoteGoodsList()
            },
            toggleWeek(val) {
                if (val === null) {
                    this.startTime = null
                    this.endTime = null
                } else {
                    let day = new Date(val);
                    let timesTramp = day.getTime();
                    this.startTime = timesTramp / 1000;
                    this.endTime = (this.startTime + (24 * 60 * 60 * 7));
                }
                this.getPromoteGoodsList()
            },
            toggleMonth(val) {
                if (val === null) {
                    this.startTime = null
                    this.endTime = null
                } else {
                    let date = new Date(val);
                    let month = (date.getMonth() + 1).toString().padStart(2, '0');
                    let year = date.getFullYear();
                    let startTime = year + '-' + month + '-' + '01';
                    let day = new Date(year, Number(month), 0);
                    let endTime = year + '-' + month + '-' + day.getDate();
                    let newStartTime = new Date(startTime.replace(/-/g, '/'));
                    let newEndTime = new Date(endTime.replace(/-/g, '/'));
                    let time1 = Date.parse(newStartTime) / 1000
                    let time2 = Date.parse(newEndTime) / 1000
                    this.startTime = time1
                    this.endTime = time2
                }
                this.getPromoteGoodsList()
            },
            // 第二栏
            getGoodsClass() {
                this.$httpStudent.axiosGet(this.$api.getGoodsClass, (res) => {
                    if (res.code === 200) {
                        this.goodsClassList = res.list
                        this.$nextTick(() => {
                            this.classifyHeight = this.$refs.classifyBox.offsetHeight
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            toggleGoodsClass(item) {
                this.goodsClassCurrent = item.id;
                this.chooseClassName = item.name
                this.amountCurrent = 'daily_sale_count'
                this.getPromoteGoodsList()
            },
            toggleGoodsClassSecond(item) {
                this.goodsClassCurrent = item.parent_id;
                this.goodsClassSecondCurrent = item.id;
                this.chooseClassName = item.class_name
                this.amountCurrent = 'daily_sale_count'
                this.getPromoteGoodsList()
            },
            // 表格
            getPromoteGoodsList() {
                let params = {
                    page: this.promoteRankPages.currentPageNum,
                    limit: this.promoteRankPages.eachPageNum,
                    order_type: this.amountCurrent
                }
                if (this.searchValue) {
                    params.goods_name = this.searchValue
                }
                if (this.chooseClassName === '' || this.chooseClassName === '综合') {
                    delete params.class_name
                } else {
                    params.class_name = this.chooseClassName
                }
                if (this.startTime) {
                    params.statr_time = this.startTime
                }
                if (this.endTime) {
                    params.end_time = this.endTime
                }
                if (this.datePickerValue) {
                    params.time_scope = this.datePickerValue / 1000
                }
                this.$httpStudent.axiosGetBy(this.$api.getGoodsList, params, (res) => {
                    if (res.code === 200) {
                        this.goodsList = res.list
                        this.promoteRankPages.total = res.total
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            promoteRankCurrentChange(val) {
                this.promoteRankPages.currentPageNum = val
                this.getPromoteGoodsList()
            },
            toggleAmount(val) {
                this.amountCurrent = val
                this.getPromoteGoodsList()
            },
            toGoodsDetail(row, type) {
                this.$router.push({
                    path: '/student/tb/goodDetail',
                    query: {
                        id: row.id,
                        type: type,
                    }
                })
            },
            //防抖
            sendAjax: _.debounce(function () {
                this.getPromoteGoodsList()
            }, 1000),
        },
        watch:{
            searchValue(d){
                this.sendAjax();
            }
        },
    }
</script>

<style scoped lang="scss">
    .promoteRank-container {
        position: relative;
        background: #fff;
        width: 100%;
        .promoteRank-top {
            padding: 20px 20px 0;
            display: flex;
            align-items: center;
            .el-date-editor {
                margin-left: 20px;
            }
            .input-with-select {
                width: 360px;
                margin-left: 20px
            }
        }
        .promoteRank-select {
            padding: 0 20px;
            margin-top: 20px;
            display: flex;
            .select-left {
                line-height: 24px;
                margin-right: 12px;
            }
            .select-right {
                width: 1%;
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                .tag-item {
                    display: flex;
                    align-items: center;
                    line-height: 24px;
                    padding: 0 8px;
                    border-radius: 4px;
                    margin-right: 12px;
                    cursor: pointer;
                    border: 1px solid transparent;
                    outline: none;
                    transition: all .3s;
                    margin-bottom: 10px;
                    .iconfont {
                        margin-left: 4px;
                        transition: all .3s;
                    }
                    &.current {
                        border-color: #1E33E3;
                        color: #1E33E3;
                        .iconfont {
                            transform: rotate(180deg);
                        }
                    }
                    &:hover {
                        .iconfont {
                            color: #1E33E3;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
    .promoteRank-popover {
        .tag-item-list {
            .second-level {
                display: inline-block;
                padding: 0 10px;
                line-height: 24px;
                cursor: pointer;
                color: #333;
                &:hover, &.current {
                    font-weight: 500;
                    color: #1E33E3;
                }
            }
        }
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
    /*::v-deep .el-radio-button__inner:hover {*/
    /*    color: #1E33E3;*/
    /*}*/
    // 表格内样式
    .listTable {
        width: 100%;
        margin-top: 10px;
        .header-event {
            cursor: pointer;
            .header-icon {
                margin-left: 6px;
                font-size: 12px;
                color: #A0A0A0;
            }
            &.current {
                color: #000;
                .header-icon {
                    color: #1E33E3;
                }
            }
        }
        .crease-box {
            .crease-tag {
                color: #666;
                background: #EEEEEE;
                line-height: 28px;
                padding: 0 10px;
                display: inline-block;
                margin-left: 6px;
                white-space: nowrap;
                .iconfont {
                    color: #A0A0A0;
                    font-size: 12px;
                    margin-right: 4px;
                }
            }
            &.current {
                .crease-num {
                    font-weight: 700;
                }
                .crease-tag {
                    color: #FF606E;
                    background: #FFF0F1;
                    .iconfont {
                        color: #FF4C5B;
                    }
                }
            }
        }
        .goods-wrapper {
            display: flex;
            align-items: center;
            .goods-cover {
                width: 46px;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-title {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                color: #333;
            }
            &:hover {
                .goods-title {
                    color: #1E33E3;
                }
            }
        }
        .goods-rank {
            font-size: 18px;
            font-weight: 500;
        }
    }
</style>